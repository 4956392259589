<template>
  <div class="rate-con">
    <div class="filter">
      考试日期
      <a-date-picker
        class="distance dis"
        format="YYYY-MM-DD"
        v-model="examDate"
        @change="changeDate"
      />
      <!-- <a-button type="primary" @click="getRate()">查询</a-button> -->
    </div>
    <div class="flex" ref="rateCon">
      <a-row :gutter="[16,16]" style="width:410px;min-width:410px;overflow-y:scroll;height:calc(100vh - 205px);">
        <a-col :sm="24" :lg="24" :xl="24" :order="1">
          <div class="white">
            <div class="title-model" >考场合格率 <a-button @click="exportOne" class="export-btn" type="danger" ghost>导出</a-button></div>
            <pay-rate  :cur-spot-name="curSpotName" :exam-date="examDate" :rate="isPay" @search="searchSale"></pay-rate> 
          </div>
        </a-col>
        <a-col :sm="24" :lg="24" :xl="24" v-if="saleRateData.length">
          <div class="white">
            <div class="title-model">业务员合格率<a-button @click="exportTwo" class="export-btn" type="danger" ghost>导出</a-button></div>
            <sale-rate :one-pay="onePay" :cur-spot-name="curSpotName" :exam-date="examDate" :rate="saleRateData" @searSale="getOneSale" />
          </div>
        </a-col>
        <a-col :sm="24" :lg="24" :xl="24" v-if="oneSaleMealRate.length">
          <div class="white">
            <div class="title-model">套餐合格率<a-button @click="exportThree" class="export-btn" type="danger" ghost>导出</a-button></div>
            <meal-rate :two-per="hotName" :cur-spot-name="curSpotName" :exam-date="examDate" :rate="oneSaleMealRate" @searStu="getStu" />
          </div>
        </a-col>
      </a-row>
      <div class="flex1" style="padding-left:30px;" >
        <div ref="tbl">
          <div class="bt" v-if="stuList.length">{{onePay==true?'收费 - ':''}}{{onePay==false?'未收费 - ':''}} {{hotName}} - {{threeMeal}} 的学员</div>
          <RateAboutStu :stuList="stuList" v-if="stuList.length" :key="maxWidth" :max-width="maxWidth" style="width:100%" />
          <div style="padding-top:20px;" v-if="stuList.length">
            <a-pagination style="float:right;text-align:center" @change="changPage" :total="total" :pageSize="fiterStu.pageSize" />
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PayRate from './components/rateComps/PayRate'
import SaleRate from './components/rateComps/SaleRate'
import MealRate from './components/rateComps/MealRate'
import RateAboutStu from './components/rateComps/RateAboutStu'
export default {
  components:{
    PayRate,
    SaleRate,
    MealRate,
    RateAboutStu
  },
  data(){
    return{
      examDate:this.$moment(new Date()).format('YYYY-MM-DD'),
      isPay:[],
      saleRateData:[],
      oneSaleMealRate:[],
      hotName:'总计',
      stuList:[],
      fiterStu:{
        pageNum:1,
        pageSize:12,
        examSpotId:this.$store.state.curSpotId,
        examDay:this.examDate,
      },
      total:0,
      maxWidth:0,
      onePay:null,
      threeMeal:"总计"
    }
  },
  mounted(){
    
    this.setWidth()
    window.onresize = () => {
      this.setWidth()
    }
    this.getRate()
  },
  computed:{
    curSpotName:function(){
      let list = this.$store.state.examSpotList
      for(var i=0;i<list.length;i++){
        if(list[i].spotId==this.$store.state.curSpotId){
          return list[i].spotName
        }
      }
    }
  },
  methods:{
    setWidth(){
      this.maxWidth = this.$refs.rateCon.offsetWidth - 430
    },
    changeDate(){
      if(this.examDate)this.examDate = this.$moment(this.examDate).format('YYYY-MM-DD')
      this.getRate()
      this.saleRateData = []
      this.getSaleRate()
      this.oneSaleMealRate = []
      this.getSaleMealRate()
      this.stuList = []
    },
    searchSale(pay){
      this.onePay = pay
      this.getSaleRate(pay)
      this.oneSaleMealRate = []
      this.getSaleMealRate()
      this.stuList = []
    },
    getOneSale(pay,cdHotAgentName){
      this.hotName = cdHotAgentName
      this.getSaleMealRate(pay,cdHotAgentName)
      this.stuList = []
    },
    getStu(pay,cdHotMealName){
      this.fiterStu.cdIsFee = pay;
      this.fiterStu.cdHotAgentName = this.hotName;
      this.threeMeal = cdHotMealName
      this.fiterStu.cdHotMealName = cdHotMealName;
      this.fiterStu.examDay = this.examDate;
      this.getStuList()
    },
    changPage(page){
      this.fiterStu.pageNum = page;
      this.getStuList()
    },
    getStatus(pay){
      let status;
      // switch(pay){
      //   case true:

      // }
    },
    /*  数据查询  */
    exportOne(){
      this.$downExe(this.$url.导出是否收费,{
        examDay:this.examDate,
        examSpotId:this.$store.state.curSpotId
      },this.curSpotName+' '+this.examDate+'考试合格率')
    },
    getRate() {
      if(this.examDate)this.examDate = this.$moment(this.examDate).format('YYYY-MM-DD')
      this.$post(this.$url.合格率统计, {
        examDay:this.examDate,
        examSpotId:this.$store.state.curSpotId
      }).then(data => {
        this.isPay = data.data.list
      });
    },
    exportTwo(){
      this.$downExe(this.$url.导出业务合格率,{
        examDay:this.examDate,
        examSpotId:this.$store.state.curSpotId,
        cdIsFee:this.onePay?this.onePay:null
      },this.curSpotName+' '+this.examDate+'业务员合格率统计')
    },
    getSaleRate(pay) {
      if(this.examDate)this.examDate = this.$moment(this.examDate).format('YYYY-MM-DD')
      this.$post(this.$url.业务员合格率统计, {
        examDay:this.examDate,
        examSpotId:this.$store.state.curSpotId,
        cdIsFee:pay?pay:null
      }).then(data => {
        this.saleRateData = data.data.list
      });
    },
    exportThree(){
      this.$downExe(this.$url.导出套餐合格率,{
        examDay:this.examDate,
        examSpotId:this.$store.state.curSpotId,
        cdIsFee:this.onePay?this.onePay:null,
        cdHotAgentName:this.hotName?this.hotName:'总计'
      },this.curSpotName+' '+this.examDate+'业务员合格率统计')
    },
    getSaleMealRate(pay,cdHotAgentName){
      this.$post(this.$url.业务员套餐合格率, {
        examDay:this.examDate,
        examSpotId:this.$store.state.curSpotId,
        cdIsFee:pay?pay:null,
        cdHotAgentName:cdHotAgentName?cdHotAgentName:'总计'
      }).then(data => {
        this.oneSaleMealRate = data.data.list
      });
    },
    getStuList(){
      this.$post(this.$url.合格率的学员, this.fiterStu).then(data => {
        this.stuList = data.data.list
        this.total = data.data.total
      });
    }
  }
}
</script>
<style scoped>
.export-btn{
  float:right;
  position:relative;
  top:-10px
}
.bt{
  line-height:40px;
  text-align:center;
  font-weight:bold;
}
.filter{
  margin-bottom:20px;
}
.white{
  padding:15px;
  background:#fff;
}
.rate-table{
  width:100%;
  text-align:center;
}
.rate-table tr td{
  border:solid 1px rgba(0,0,0,0.1);
  line-height:40px;
}
.title{
  font-size:18px;
  font-weight:bold;
}
.title-model{
  margin:10px 0 15px;
  border-left:solid 3px #2980ff;
  padding-left:10px;
  font-size:18px;
  color:#2980ff;
  line-height:20px;
}
</style>