<template>
  <div>
    <!-- :style="{'max-width':maxWidth+'px'}" -->
    <a-table :scroll="{ x:700 }" :columns="roleColums" :data-source="stuList" :pagination="false" :style="{'width':maxWidth+'px'}">
      <span slot="amount" slot-scope="text,record">￥{{text}}</span>
      <span slot="cancelId" slot-scope="text,record">
        <span v-if="!record.cancelName">否</span>
        <a-tooltip v-else placement="rightBottom">
          <template slot="title">
            <p>{{ record.cancelName }}</p>
            <p>{{ record.cancelDt }}</p>
          </template>
          <a-button>已作废</a-button>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click.prevent="printStu(record)">打印</a>
        <a-divider type="vertical" />
        <span class="delete-btn"  @click.prevent="recordGrade(record)" >录入成绩</span>
        <a-divider type="vertical" />
        <span class="delete-btn" @click="cancelPay(record)">作废</span>
      </span>
    </a-table>
  </div>
</template>
<script>
const roleColums = [
  {
    title: '姓名',
    dataIndex: 'studentName',
    key: 'studentName',
    fixed: 'left',
    width:80,
  },
  {
    title: '身份证',
    dataIndex: 'idCard',
    key: 'idCard',
    width:165,
  },
  {
    title: '流水号',
    dataIndex: 'billNo',
    key: 'billNo',
    width:160,
  },
  {
    title: '车号',
    dataIndex: 'carHotCode',
    key: 'carHotCode',
    width:50,
  },
  {
    title: '车型',
    dataIndex: 'licName',
    key: 'licName',
    width:50,
  },
  {
    title: '业务员',
    dataIndex: 'hotAgentName',
    key: 'hotAgentName',
    width:80,
  },
  {
    title: '成绩',
    dataIndex: 'examScore',
    key: 'examScore',
    width:70,
  },
  {
    title: '是否合格',
    dataIndex: 'examPass',
    key: 'examPass',
    width:78,
  },
  {
    title: '套餐',
    dataIndex: 'hotMealName',
    key: 'hotMealName',
    width:100,
  },
  {
    title: '考试日期',
    dataIndex: 'examDay',
    key: 'examDay',
    width:98,
  },
  {
    title: '数量',
    dataIndex: 'practiceNum',
    key: 'practiceNum',
    width:50,
  },
  {
    title: '实收金额',
    dataIndex: 'amount',
    key: 'amount',
    width:78,
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: '支付方式',
    dataIndex: 'chargeWay',
    key: 'chargeWay',
    width:80,
  },
  {
    title: '收费员',
    dataIndex: 'chargerName',
    key: 'chargerName',
    width:80,
  },
  {
    title: '收费时间',
    dataIndex: 'chargeDt',
    key: 'chargeDt',
    width:165,
  },
  
  {
    title: '强训日期',
    dataIndex: 'practiceDay',
    key: 'practiceDay',
    width:95,
  },
  {
    title: '考试次数',
    dataIndex: 'examTimes',
    key: 'examTimes',
    width:78,
  },
  {
    title: '是否作废',
    scopedSlots: { customRender: 'cancelId' },
    key: 'cancelId',
    width:78,
  },
  {
    title: '状态',
    dataIndex: 'statusName',
    key: 'statusName',
    width:60,
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width:180,
  },
]
export default {
  name:'rateaboutstu',
  props:{
    stuList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    maxWidth:Number
  },
  activated(){
    this.getStuList()
  },
  deactivated(){
  },
  data() {
    return {
      roleColums,
    }
  },
  methods: {
    
  },
}
</script>
<style scoped>

</style>