<template>
  <div>
    <table class="rate-table">
      <tr>
        <td colspan="7" class="title">{{curSpotName}}--{{examDate}}套餐合格率{{twoPer=="总计"?'':'('+twoPer+')'}}</td>
      </tr>
      <tr>
        <td></td>
        <td>合格</td>
        <td>不合格</td>
        <td>未考</td>
        <td>总人数</td>
        <td>合格率</td>
        <td>查看学员</td>
      </tr>
      <tr v-for="(item,index) in rate" :key="index" @click="$emit('searStu',item.isFee,item.item)">
        <td >{{item.item}}</td>
        <td>{{item.passNum}}</td>
        <td>{{item.failNum}}</td>
        <td>{{item.abandonNum}}</td>
        <td>{{item.totalNum}}</td>
        <td class="ligh-text">{{(Number(item.passRate)*100).toFixed(1)}}%</td>
        <td class="ligh-text">学员详情</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props:{
    examDate:{
      type:String,
      default:''
    },
    curSpotName:{
      type:String,
      default:''
    },
    rate:{
      type:Array,
      defaullt:()=>{
        return []
      }
    },
    twoPer:{
      type:String,
      default:'总计'
    }
  },
  mounted(){
  }
}
</script>
<style scoped>
.rate-table{
  width:100%;
  text-align:center;
}
.rate-table tr td{
  border:solid 1px rgba(0,0,0,0.1);
  line-height:40px;
}
.title{
  font-size:18px;
  font-weight:bold;
}
.title-model{
  margin:10px 0 15px;
  border-left:solid 3px #2980ff;
  padding-left:10px;
  font-size:18px;
  color:#2980ff;
  line-height:20px;
}
</style>